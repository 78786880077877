
import { defineComponent, ref, reactive, onBeforeMount, onMounted, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRoute } from 'vue-router'
import ApiService from "@/core/services/ApiService";
import router from "@/router";
import store from "@/store";
import moment from "moment";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ElMessageBox } from "element-plus";

export default defineComponent({
    name: "assessment-detail-modal",
    components: {
    },
    setup() {
        const route = useRoute();
        const assessmentId = +route.params.id; 
        const semesters = ref([] as any);
        const subjects = ref([] as any);
        const selectedSemester = ref(null as any);
        const selectedSubject = ref(null as any);
        const formRef = ref<null | HTMLFormElement>(null);
        const submitButton = ref<HTMLElement | null>(null);
        const assessmentDetail = reactive({} as any);
        const user = store.getters.currentUser;
        const types = ref([]);
        const skillAreas = ref([]);
        
        const getAssessmentTypes = async() =>{
            await new Promise((resolve, reject) => {  
                ApiService.setHeader();          
                ApiService.get("assessment/types")
                    .then(({ data }) => {
                        types.value = data.data;
                        resolve(data.data);
                    })
                    .catch(({ response }) => {
                        reject(new Error ('getAssessmentTypes => '+response.data.errors));
                    });
            });
        }

        const getSemesters = () : any => {
            return new Promise((resolve, reject) => {  
                ApiService.setHeader();          
                ApiService.get("class/semesters")
                    .then(({ data }) => {
                        let sortedData = data.data.sort(function(a,b) { 
                            if(a.id == b.id) return 0;

                            if (Date.parse(a.start_date!) < Date.parse(b.start_date!))
                                return 1;
                            if (Date.parse(a.start_date!) > Date.parse(b.start_date!))
                                return -1;
                            return 0;
                        });
                        semesters.value = sortedData;
                        resolve(sortedData);
                    })
                    .catch((error) => {
                        reject(new Error ('getSemesters error => '+error));
                    });
            });
        }

        const getClasses = async() => {
            subjects.value = [];
            await new Promise<void>((resolve, reject) => {
                ApiService.setHeader();    
                ApiService.get("class/"+selectedSemester.value)
                    .then(async({ data }) => {
                    data.data.forEach(program => {
                        program.levels.forEach(level => {
                        level.sessions.forEach(session => {
                            session.subjects.forEach(subject =>{
                            subjects.value.push({subjectId: subject.id, label: program.code+'/'+level.code+'/'+session.code+'/'+subject.name})
                            })
                        })
                        })
                    });

                    resolve();
                    })
                    .catch((error) => {
                        reject(new Error ('getClasses error => '+error));
                    });
            });
        }

        const getAssessment = async () => {
            await new Promise((resolve, reject) => {        
                ApiService.setHeader();    
                ApiService.get("assessment/"+assessmentId)
                    .then(({ data }) => {
                        if(data.data){
                            data.data.student_grades = data.data.student_grades.filter(x=>x.student!=null);
                            Object.assign(assessmentDetail,data.data);
                        }

                        selectedSubject.value = data.data.subject_id;
                        assessmentDetail.period = [];
                        assessmentDetail.period[0] = data.data.assessment_start_date;
                        assessmentDetail.period[1] = data.data.assessment_end_date;
                        resolve(data.data);
                    })
                    .catch(({ response }) => {
                        reject(new Error ('getAgents error => '+response.data.errors));
                    });
            });
        }

        const getSkillAreas = () => {
            return new Promise((resolve, reject) => {   
                ApiService.setHeader();         
                ApiService.get("class/subject/"+selectedSubject.value)
                    .then(({ data }) => {
                        if(data.data.skill_area){
                            skillAreas.value = data.data.skill_area.split(';');
                        }
                        resolve(data.data);
                    })
                    .catch((error) => {
                        reject(new Error ('getSemesters error => '+error));
                    });
            });
        }

        const getSubjectStudents = () : any => {
            return new Promise((resolve, reject) => {   
                    ApiService.setHeader();         
                    ApiService.get("class-student/students/"+selectedSubject.value)
                        .then(({ data }) => {
                            if(!assessmentDetail.student_grades){
                                assessmentDetail.student_grades = [];           
                            }       
                            data.data.filter(x=>x.student != null).forEach(student => {
                                if(assessmentDetail.student_grades.filter(x=> x.student_id == student.student_id ).length == 0){
                                    assessmentDetail.student_grades.push({student_id: student.student_id, student:student.student, achieved_point:0})
                                }
                            });
                            resolve(data.data);
                        })
                        .catch((error) => {
                            reject(new Error ('getSemesters error => '+error));
                        });
                });
        }

        const deleteAssessment = () =>{
            ElMessageBox.confirm('Are you sure to delete this class?')
            .then(() => {
                ApiService.setHeader();
                ApiService.delete("assessment/"+assessmentId)
                    .then(() => {
                        Swal.fire({
                            text: "Assessment is deleted successfully",
                            icon: "success",
                            buttonsStyling: false,  
                            confirmButtonText: "Ok, got it!",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            })
                            .then(() => {
                                router.push({ name: "assessments"});
                            });
                    })
                    .catch(({ response }) => {
                        Swal.fire({
                        text: "Failed to delete the assessment. "+response.data.errors,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        })
                    });
            });
        }
        
        const submit = () => {
            assessmentDetail.user_id=user.id;
            assessmentDetail.assessment_start_date=assessmentDetail.period[0];
            assessmentDetail.assessment_end_date=assessmentDetail.period[1];
            delete assessmentDetail.period;
            if(!assessmentDetail.subject_id)
            {
              assessmentDetail.subject_id = selectedSubject.value;  
            } 

            submitButton.value?.setAttribute("data-kt-indicator", "on");
            
            if(assessmentId === 0){
                ApiService.setHeader();
                ApiService.post("assessment", assessmentDetail)
                    .then(() => {
                        Swal.fire({
                        text: "Assessment information has been successfully created!",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(() => {
                            router.push({ name: "assessments"});
                        });
                    })
                    .catch(({ response }) => {
                        Swal.fire({
                        text: "Failed to create the new assessment. "+response.data.errors,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        })
                    })
                    .finally(() =>{
                        submitButton.value?.removeAttribute("data-kt-indicator");
                    });
            }
            else
            {
                ApiService.setHeader();
                ApiService.update("assessment", assessmentId, assessmentDetail)
                    .then(() => {
                        Swal.fire({
                            text: "Assessment is updated successfully",
                            icon: "success",
                            buttonsStyling: false,  
                            confirmButtonText: "Ok, got it!",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            })
                            .then(() => {
                                router.push({ name: "assessments"});
                            });
                    })
                    .catch(({ response }) => {
                        Swal.fire({
                        text: "Failed to update the assessment"+response.data.errors,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        })
                    })
                    .finally(() =>{
                        submitButton.value?.removeAttribute("data-kt-indicator");
                    });
            }
        };

        const updateSubject = async() =>{
            await getClasses();
        }

        const updateStudents = async() =>{
            await getSubjectStudents();
            await getSkillAreas();
        }

        const backToList = () =>{
            router.go(-1);
        }

        onBeforeMount(async () => {
          await getAssessmentTypes();
          if(assessmentId !== 0){
            await getAssessment().then(async() =>{
                await getSubjectStudents();
                await getSkillAreas();
            });
          }
          else{
              await getSemesters().then();
          }
        })

        onMounted(() => {
            setCurrentPageBreadcrumbs(assessmentId != 0 ? "Assessment Detail": "Create Assessment", ["Academics", "Assessment & Grading"]);
        });

        return {
            submit,
            formRef,
            assessmentDetail,
            assessmentId,
            types,
            semesters,
            subjects,
            selectedSemester,
            selectedSubject,
            moment,
            updateSubject,
            updateStudents,
            deleteAssessment,
            backToList,
            skillAreas
        };
    },
});
