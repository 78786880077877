<template>
  <div class="card mb-5 mb-xl-10">
    <div class="card-header p-8 pb-0">
        <h2 v-if="assessmentId===0">Create assessment</h2>
        <h2 v-else>Assessment detail</h2>
    </div>
    <div class="card-body pt-9 pb-0">
        <el-form
            id="modal_agent_detail_form"
            class="form"
            :model="assessmentDetail"
            ref="formRef"
        >
            <div class="modal-body py-lg-10 px-lg-10">
                <div class="row mb-6" v-if="assessmentId === 0">
                    <label for="type" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span class="required">Semester</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <el-form-item prop="semester">
                            <el-select
                                class="form-select-solid w-100"
                                placeholder="Select semester"
                                v-model="selectedSemester"
                                @change="updateSubject"
                            >
                                <el-option 
                                v-for="semester in semesters" 
                                :label="semester.name+'('+semester.code+') : '+moment(semesters.start_date).format('YYYY.MM.DD')+'~'+moment(semester.end_date).format('YYYY.MM.DD')" 
                                :value="semester.id"
                                :key="semester">
                                {{semester.name+'('+semester.code+') : '+moment(semesters.start_date).format('YYYY.MM.DD')+'~'+moment(semester.end_date).format('YYYY.MM.DD')}}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="row mb-6" v-if="assessmentId === 0">
                    <label for="type" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span class="required">Subject</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <el-form-item prop="subject">
                                <el-select
                                    class="form-select-solid w-100"
                                    placeholder="Select subject"
                                    v-model="selectedSubject"
                                    @change="updateStudents"
                                >
                                    <el-option 
                                    v-for="subject in subjects" 
                                    :label="subject.label" 
                                    :value="subject.subjectId"
                                    :key="subject">
                                    </el-option>
                                </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="row mb-6" v-if="assessmentDetail.subject">
                    <label for="type" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span class="required">Subject</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <el-form-item prop="subject">
                            {{assessmentDetail.subject.session.level.program.semester.name}}/{{assessmentDetail.subject.session.level.program.name}}/{{assessmentDetail.subject.session.level.name}}/{{assessmentDetail.subject.session.name}}/{{assessmentDetail.subject.name}}
                        </el-form-item>
                    </div>
                </div>
                <div class="row mb-6">
                    <label for="type" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span class="required">Type</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <el-select
                            class="form-select-solid w-100"
                            placeholder="Select assessment type"
                            v-model="assessmentDetail.type_id">
                            <el-option v-for="type in types" 
                                :label="type.name"   
                                :value="type.id" 
                                :key="type.id">
                                {{type.name}}
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="row mb-6">
                    <label for="type" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span class="required">Skill Area</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <el-select
                            class="form-select-solid w-100"
                            placeholder="Select skill area"
                            v-model="assessmentDetail.skill_area">
                            <el-option v-for="skill in skillAreas" 
                                :label="skill"   
                                :value="skill" 
                                :key="skill">
                                {{skill}}
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="row mb-6">
                    <label for="title" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span class="required">Title</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <el-form-item prop="title">
                            <input 
                                type="text" 
                                name="title" 
                                class="form-control form-control-solid" 
                                placeholder="Title" 
                                v-model="assessmentDetail.title"/>
                        </el-form-item>
                    </div>
                </div>
                <div class="row mb-6">
                    <label for="assessment_period" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span>Assessment Period</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                    <el-date-picker
                            v-model="assessmentDetail.period"
                            type="daterange"
                            range-separator="To"
                            start-placeholder="Start date"
                            end-placeholder="End date"
                            class="w-100"
                            format="MM/DD/YYYY">
                    </el-date-picker>
                    </div>
                </div>
                <div class="row mb-6">
                    <label for="content" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span>Content</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                    <el-form-item prop="content">
                        <el-input
                        v-model="assessmentDetail.content"
                        type="textarea"
                        rows="3"
                        name="assessmentDetail"
                        placeholder="Type the assessment content and details"
                        />
                    </el-form-item>
                    </div>
                </div>
                <div class="row mb-6">
                    <label for="agent_email" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span class="required">Max points</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <el-form-item prop="maxPoints">
                            <input 
                                type="number" 
                                name="maxPoints" 
                                class="form-control form-control-solid" 
                                placeholder="Max Point" 
                                v-model="assessmentDetail.max_point"/>
                        </el-form-item>
                    </div>
                </div>                
                <div class="row mb-6">
                    <label class="col-lg-3 col-form-label fw-bold fs-6">
                        Grading
                    </label>
                    <div class="col-lg-9 align-items-center">
                        <div>
                            <table class="table table-row-dashed table-row-gray-300 gy-2" v-if="assessmentDetail.student_grades">
                                <thead>
                                    <tr class="fw-bolder fs-5 text-gray-800">
                                        <th>Student Id</th>
                                        <th>Name</th>
                                        <th>Point</th>
                                    </tr>
                                </thead>
                                <tbody v-if="assessmentDetail.student_grades.length > 0">
                                    <tr v-for="grade in assessmentDetail.student_grades" :key="grade.id">
                                        <td style="vertical-align:middle;">{{grade.student.student_id}}</td>
                                        <td style="vertical-align:middle;">{{grade.student.first_name}} {{grade.student.last_name}}</td>
                                        <td>
                                            <input type="number" class="form-control form-control-solid px-5" style="width: inherit;padding: 7px;" 
                                                v-model="grade.achieved_point"/>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="3" style="text-align:center">
                                            No student data
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                        </div> 
                    </div>
                </div>
            </div>
        </el-form>
        <div class="modal-footer flex-center">
            <button
              type="button"
              id="modal_agent_detail_submit"
              class="btn btn-success"
              @click="backToList"
            >
              Back to list
            </button>

            <button
              type="button"
              id="modal_agent_detail_submit"
              class="btn btn-primary"
              ref="submitButton"
              @click="submit"
            >
              <span class="indicator-label"> Submit </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>

            <button
              type="reset"
              id="assessment_detail_delete_btn"
              class="btn btn-danger me-3"
              @click="deleteAssessment"
            >
              Delete
            </button>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onBeforeMount, onMounted, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRoute } from 'vue-router'
import ApiService from "@/core/services/ApiService";
import router from "@/router";
import store from "@/store";
import moment from "moment";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ElMessageBox } from "element-plus";

export default defineComponent({
    name: "assessment-detail-modal",
    components: {
    },
    setup() {
        const route = useRoute();
        const assessmentId = +route.params.id; 
        const semesters = ref([] as any);
        const subjects = ref([] as any);
        const selectedSemester = ref(null as any);
        const selectedSubject = ref(null as any);
        const formRef = ref<null | HTMLFormElement>(null);
        const submitButton = ref<HTMLElement | null>(null);
        const assessmentDetail = reactive({} as any);
        const user = store.getters.currentUser;
        const types = ref([]);
        const skillAreas = ref([]);
        
        const getAssessmentTypes = async() =>{
            await new Promise((resolve, reject) => {  
                ApiService.setHeader();          
                ApiService.get("assessment/types")
                    .then(({ data }) => {
                        types.value = data.data;
                        resolve(data.data);
                    })
                    .catch(({ response }) => {
                        reject(new Error ('getAssessmentTypes => '+response.data.errors));
                    });
            });
        }

        const getSemesters = () : any => {
            return new Promise((resolve, reject) => {  
                ApiService.setHeader();          
                ApiService.get("class/semesters")
                    .then(({ data }) => {
                        let sortedData = data.data.sort(function(a,b) { 
                            if(a.id == b.id) return 0;

                            if (Date.parse(a.start_date!) < Date.parse(b.start_date!))
                                return 1;
                            if (Date.parse(a.start_date!) > Date.parse(b.start_date!))
                                return -1;
                            return 0;
                        });
                        semesters.value = sortedData;
                        resolve(sortedData);
                    })
                    .catch((error) => {
                        reject(new Error ('getSemesters error => '+error));
                    });
            });
        }

        const getClasses = async() => {
            subjects.value = [];
            await new Promise<void>((resolve, reject) => {
                ApiService.setHeader();    
                ApiService.get("class/"+selectedSemester.value)
                    .then(async({ data }) => {
                    data.data.forEach(program => {
                        program.levels.forEach(level => {
                        level.sessions.forEach(session => {
                            session.subjects.forEach(subject =>{
                            subjects.value.push({subjectId: subject.id, label: program.code+'/'+level.code+'/'+session.code+'/'+subject.name})
                            })
                        })
                        })
                    });

                    resolve();
                    })
                    .catch((error) => {
                        reject(new Error ('getClasses error => '+error));
                    });
            });
        }

        const getAssessment = async () => {
            await new Promise((resolve, reject) => {        
                ApiService.setHeader();    
                ApiService.get("assessment/"+assessmentId)
                    .then(({ data }) => {
                        if(data.data){
                            data.data.student_grades = data.data.student_grades.filter(x=>x.student!=null);
                            Object.assign(assessmentDetail,data.data);
                        }

                        selectedSubject.value = data.data.subject_id;
                        assessmentDetail.period = [];
                        assessmentDetail.period[0] = data.data.assessment_start_date;
                        assessmentDetail.period[1] = data.data.assessment_end_date;
                        resolve(data.data);
                    })
                    .catch(({ response }) => {
                        reject(new Error ('getAgents error => '+response.data.errors));
                    });
            });
        }

        const getSkillAreas = () => {
            return new Promise((resolve, reject) => {   
                ApiService.setHeader();         
                ApiService.get("class/subject/"+selectedSubject.value)
                    .then(({ data }) => {
                        if(data.data.skill_area){
                            skillAreas.value = data.data.skill_area.split(';');
                        }
                        resolve(data.data);
                    })
                    .catch((error) => {
                        reject(new Error ('getSemesters error => '+error));
                    });
            });
        }

        const getSubjectStudents = () : any => {
            return new Promise((resolve, reject) => {   
                    ApiService.setHeader();         
                    ApiService.get("class-student/students/"+selectedSubject.value)
                        .then(({ data }) => {
                            if(!assessmentDetail.student_grades){
                                assessmentDetail.student_grades = [];           
                            }       
                            data.data.filter(x=>x.student != null).forEach(student => {
                                if(assessmentDetail.student_grades.filter(x=> x.student_id == student.student_id ).length == 0){
                                    assessmentDetail.student_grades.push({student_id: student.student_id, student:student.student, achieved_point:0})
                                }
                            });
                            resolve(data.data);
                        })
                        .catch((error) => {
                            reject(new Error ('getSemesters error => '+error));
                        });
                });
        }

        const deleteAssessment = () =>{
            ElMessageBox.confirm('Are you sure to delete this class?')
            .then(() => {
                ApiService.setHeader();
                ApiService.delete("assessment/"+assessmentId)
                    .then(() => {
                        Swal.fire({
                            text: "Assessment is deleted successfully",
                            icon: "success",
                            buttonsStyling: false,  
                            confirmButtonText: "Ok, got it!",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            })
                            .then(() => {
                                router.push({ name: "assessments"});
                            });
                    })
                    .catch(({ response }) => {
                        Swal.fire({
                        text: "Failed to delete the assessment. "+response.data.errors,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        })
                    });
            });
        }
        
        const submit = () => {
            assessmentDetail.user_id=user.id;
            assessmentDetail.assessment_start_date=assessmentDetail.period[0];
            assessmentDetail.assessment_end_date=assessmentDetail.period[1];
            delete assessmentDetail.period;
            if(!assessmentDetail.subject_id)
            {
              assessmentDetail.subject_id = selectedSubject.value;  
            } 

            submitButton.value?.setAttribute("data-kt-indicator", "on");
            
            if(assessmentId === 0){
                ApiService.setHeader();
                ApiService.post("assessment", assessmentDetail)
                    .then(() => {
                        Swal.fire({
                        text: "Assessment information has been successfully created!",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(() => {
                            router.push({ name: "assessments"});
                        });
                    })
                    .catch(({ response }) => {
                        Swal.fire({
                        text: "Failed to create the new assessment. "+response.data.errors,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        })
                    })
                    .finally(() =>{
                        submitButton.value?.removeAttribute("data-kt-indicator");
                    });
            }
            else
            {
                ApiService.setHeader();
                ApiService.update("assessment", assessmentId, assessmentDetail)
                    .then(() => {
                        Swal.fire({
                            text: "Assessment is updated successfully",
                            icon: "success",
                            buttonsStyling: false,  
                            confirmButtonText: "Ok, got it!",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            })
                            .then(() => {
                                router.push({ name: "assessments"});
                            });
                    })
                    .catch(({ response }) => {
                        Swal.fire({
                        text: "Failed to update the assessment"+response.data.errors,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        })
                    })
                    .finally(() =>{
                        submitButton.value?.removeAttribute("data-kt-indicator");
                    });
            }
        };

        const updateSubject = async() =>{
            await getClasses();
        }

        const updateStudents = async() =>{
            await getSubjectStudents();
            await getSkillAreas();
        }

        const backToList = () =>{
            router.go(-1);
        }

        onBeforeMount(async () => {
          await getAssessmentTypes();
          if(assessmentId !== 0){
            await getAssessment().then(async() =>{
                await getSubjectStudents();
                await getSkillAreas();
            });
          }
          else{
              await getSemesters().then();
          }
        })

        onMounted(() => {
            setCurrentPageBreadcrumbs(assessmentId != 0 ? "Assessment Detail": "Create Assessment", ["Academics", "Assessment & Grading"]);
        });

        return {
            submit,
            formRef,
            assessmentDetail,
            assessmentId,
            types,
            semesters,
            subjects,
            selectedSemester,
            selectedSubject,
            moment,
            updateSubject,
            updateStudents,
            deleteAssessment,
            backToList,
            skillAreas
        };
    },
});
</script>
